@use "sass:math";
@import "../colors-theme";

html {
  @each $name, $value in $neutral-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($neutral-palette, contrast) {
        --neutral-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --neutral-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $shadows-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($shadows-palette, contrast) {
        --shadows-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --shadows-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $primary-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($primary-palette, contrast) {
        --primary-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --primary-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $dark-blue-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($dark-blue-palette, contrast) {
        --dark-blue-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --dark-blue-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $light-blue-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($light-blue-palette, contrast) {
        --light-blue-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --light-blue-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $cyan-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($cyan-palette, contrast) {
        --cyan-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --cyan-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $success-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($success-palette, contrast) {
        --success-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --success-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $error-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($error-palette, contrast) {
        --error-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --error-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $warning-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($warning-palette, contrast) {
        --warning-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --warning-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $info-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($info-palette, contrast) {
        --info-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --info-palette-#{$name}: #{$value};
    }
  }
  @each $name, $value in $texts-palette {
    @if $name == "contrast" {
      @each $name, $value in map-get($texts-palette, contrast) {
        --texts-palette-contrast-#{$name}: #{$value};
      }
    } @else {
      --texts-palette-#{$name}: #{$value};
    }
  }
}