.vcim {
  &-accordion.mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      border: 2px solid var(--neutral-palette-8);
      background-color: var(--neutral-palette-1);
      color: var(--neutral-palette-contrast-1);
      border-radius: 15px !important;
      transition: 0.2s linear 0s;
      .mat-expansion-panel-header {
        .mat-content {
          margin: 0;
        }
        height: auto;
        &:hover {
          background-color: transparent;
        }
        .mat-expansion-panel-header-title {
          color: var(--neutral-palette-contrast-1);
          margin: 0;
        }
      }
      &.mat-expanded {
        border: 2px solid var(--light-blue-palette-50);
        .vcim-icon.ic-arow-down {
          transform: rotate(180deg);
        }
      }
      &:hover {
        border: 2px solid var(--light-blue-palette-50);
      }
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
  &-accordion-filter.mat-accordion {
    background-color: var(--neutral-palette-1);
    color: var(--neutral-palette-contrast-1);
    .mat-expansion-panel-header {
      border-radius: 0;
    }
    .mat-expansion-panel-header-title {
      margin: 0;
    }
    .mat-expansion-panel {
      border-bottom: 1px solid var(--neutral-palette-8);
      &:first-child {
        border-top: 1px solid var(--neutral-palette-8);
      }
    }
  }
}
