html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--neutral-palette-1);
  color: var(--neutral-palette-contrast-1);

  main {
    min-height: 50vh;
  }
}

:not(.vcim-inner-html *) {
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  font-family: 'Roboto', Mardoto, sans-serif;
  padding: 0;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

textarea {
  resize: none;
}
