@use 'sass:map';
@use 'sass:math';
@import '../variables';

.vcim-title-1 {
  font-weight: 800;
  font-size: 2.7rem;
  line-height: 3.75rem;
}
.vcim-headline-1 {
  font-weight: 800;
  font-size: 3rem;
  line-height: 4.5rem;
}
.vcim-headline-2 {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.375rem;
}
.vcim-headline-3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}
.vcim-headline-4 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
}
.vcim-headline-5 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.475rem;
}
.vcim-subtitle {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.vcim-body-1 {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
}
.vcim-body-2 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media screen and (max-width: #{math.div(map.get($breakpoints,lg),16)}rem) {
  .vcim-title-1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .vcim-headline-1 {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }

  .vcim-headline-2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .vcim-headline-3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .vcim-headline-4 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .vcim-headline-5 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-body-1 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-body-2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: #{math.div(map.get($breakpoints,md),16)}rem) {
  .vcim-title-1 {
    font-size: 1.3rem;
    line-height: 1.375rem;
  }

  .vcim-headline-1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .vcim-headline-2 {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .vcim-headline-3 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .vcim-headline-4 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-headline-5 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-body-1 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .vcim-body-2 {
    font-size: 0.875rem;
    line-height: 1.225rem;
  }
}
