@use 'sass:math';
@import "../variables";

@each $name, $val in $breakpoints {
  @media screen and (min-width: #{math.div($val,16)}rem) {
      .#{$name}-flex,
      .#{$name}-d-flex {
        display: flex !important;
      }

      .#{$name}-inline-flex,
      .#{$name}-d-inline-flex {
        display: inline-flex !important;
      }

      .#{$name}-flex-column,
      .#{$name}-flex-col {
        flex-direction: column !important;
      }
      
      .#{$name}-flex-row {
        flex-direction: row !important;
      }
      .#{$name}-column-reverse {
        flex-direction: column-reverse !important;
      }
      .#{$name}-row-reverse {
        flex-direction: row-reverse !important;
      }

      .#{$name}-flex-wrap {
        flex-wrap: wrap !important;
      }

      .#{$name}-flex-grow {
        flex-grow: 1 !important;
      }

      .#{$name}-items-start {
        align-items: flex-start !important;
      }

      .#{$name}-items-end {
        align-items: flex-end !important;
      }

      .#{$name}-items-center {
        align-items: center !important;
      }

      .#{$name}-items-baseline {
        align-items: baseline !important;
      }

      .#{$name}-items-stretch {
        align-items: stretch !important;
      }

      .#{$name}-justify-start {
        justify-content: flex-start !important;
      }

      .#{$name}-justify-end {
        justify-content: flex-end !important;
      }

      .#{$name}-justify-center {
        justify-content: center !important;
      }

      .#{$name}-justify-between {
        justify-content: space-between !important;
      }

      .#{$name}-justify-around {
        justify-content: space-around !important;
      }

      .#{$name}-justify-evenly {
        justify-content: space-evenly !important;
      }
      .#{$name}-d-none {
        display: none !important;
      }
      .#{$name}-d-block {
        display: block !important;
      }
      .w-#{$name}-auto {
        width: auto !important;
      }
    }
  
}
