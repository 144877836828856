@import '../variables';

.flex,
.d-flex {
  display: flex !important;
}
.inline-flex,
.d-inline-flex {
  display: inline-flex !important;
}

.flex-column,
.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}
.row-reverse {
  flex-direction: row-reverse !important;
}
.wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}
.flex-1 {
  flex: 1 !important;
}
.pre-line {
  white-space: pre-line !important;
}

.relative {
  position: relative !important;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}

.h-100 {
  height: 100vh !important;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.w-full {
  width: 100% !important;
}

.w-100 {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.pointer,
.cursor-pointer {
  cursor: pointer !important;
}

.pointer-none {
  pointer-events: none !important;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.visually-hidden {
  position: absolute !important;
  opacity: 0 !important;
  z-index: -1 !important;
  pointer-events: none !important;
  width: 0 !important;
  height: 0 !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.truncate {
  white-space: nowrap !important;
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
}

.truncate-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}
.truncate-3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}
.truncate-4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.break-word {
  word-break: break-word !important;
}

.animated {
  transition: 0.2s linear 0s !important;
}
.underline {
  text-decoration: underline !important;
}

.vcim-container {
  max-width: 120rem;
  width: 100%;
  margin: auto;
  padding-left: 9.375rem;
  padding-right: 9.375rem;
  display: flex;
  flex-direction: column;
}

@include media-xl {
  .vcim-container {
    max-width: 76rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

@include media-lg {
  .vcim-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@include media-sm {
  .vcim-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.vcim-inner-html {
  > *:first-child {
    margin-top: 0;
  }
  a {
    color: var(--info-palette-500);
    text-decoration: none;
    :hover {
      color: var(--info-palette-700);
    }
  }
  ul,
  ol {
    padding-left: 2rem;
  }
  ul li {
    list-style: initial;
    padding-left: 1rem;
  }
  ol li {
    list-style: decimal;
    padding-left: 1rem;
  }
  u {
    text-decoration: underline;
  }
  blockquote,
  q {
    border-left: 5px solid #ccc;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: rgb(204, 204, 204);
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  img {
    max-width: 100%;
  }
  figure {
    margin-left: 0;
    margin-right: 0;
    img,
    table {
      width: 100%;
    }
  }
  & .image-style-block-align-left,
  & .image-style-block-align-right {
    max-width: calc(100% - 16px);
  }

  & .image-style-align-left,
  & .image-style-align-right {
    clear: none;
  }

  & .image-style-side {
    float: right;
    margin-left: 16px;
    max-width: 50%;
  }

  & .image-style-align-left {
    float: left;
    margin-right: 16px;
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: 16px;
  }

  & .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
  }

  & .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }

  & p + .image-style-align-left,
  & p + .image-style-align-right,
  & p + .image-style-side {
    margin-top: 0;
  }

  & .image-inline {
    &.image-style-align-left,
    &.image-style-align-right {
      margin-top: var(--ck-inline-image-style-spacing);
      margin-bottom: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-left {
      margin-right: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-right {
      margin-left: var(--ck-inline-image-style-spacing);
    }
  }

  .vcim-button-link {
    background-color: var(--light-blue-palette-50);
    color: var(--light-blue-palette-contrast-50);
    border: 2px solid var(--light-blue-palette-50);
    padding: 0.7rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1.125rem;
    display: inline-flex;
    &.vcim-not-clickable {
      background-color: var(--neutral-palette-4);
      color: var(--neutral-palette-contrast-1);
      border: 2px solid transparent;
    }
    &:hover {
      box-shadow: var(--shadows-palette-4);
    }
  }
  h3 {
    font-size: 1.7rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  h4 {
    font-size: 1.5rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}

.inner-html-title {
  line-height: 1 !important;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.chip {
  padding: 0.1rem 1rem;
  border-radius: 5px;
  display: inline-flex;
  &.done {
    color: var(--info-palette-500);
    background-color: var(--info-palette-100);
  }
  &.cancel {
    color: var(--error-palette-500);
    background-color: var(--error-palette-100);
  }
  &.paid {
    color: var(--success-palette-500);
    background-color: var(--success-palette-100);
  }
  &.booked {
    color: var(--warning-palette-500);
    background-color: var(--warning-palette-100);
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: initial !important;
}

.grey {
  color: var(--neutral-palette-13);
}

.fixed-header {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 100;
}
.global-banner {
  .owl-theme,
  owl-stage,
  owl-stage > div,
  .owl-item {
    height: 100%;
    display: inline-flex !important;
  }
}
