@use "sass:math";

$spacing-sizes: (0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 15, 16, 20, 25, 27, 37); // *4

$grid-cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
$breakpoints: (
  "xs": 450,
  "sm": 600,
  "md": 900,
  "lg": 1200,
  "xl": 1536,
);
$grid-col-size: math.div(100%, 12);

$font-sizes: (10, 12, 14, 16, 18, 20, 24, 40, 48);
$font-weights: (500, 600, 700);

@mixin media-xs {
  @media screen and (max-width:  #{math.div(449,16)}rem) {
    @content;
  }
}

@mixin media-sm {
  @media screen and (max-width:#{math.div(599,16)}rem) {
    @content;
  }
}

@mixin media-md {
  @media screen and (max-width:#{math.div(899,16)}rem) {
    @content;
  }
}

@mixin media-lg {
  @media all and (max-width:#{math.div(1199,16)}rem) {
    @content;
  }
}

@mixin media-xl {
  @media all and (max-width:#{math.div(1535,16)}rem) {
    @content;
  }
}
