@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Mardoto;
  src: url("Mardoto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
