@import '../variables';

button.mat-mdc-unelevated-button {
  &.color {
    &-primary {
      background-color: var(--light-blue-palette-50);
      color: var(--light-blue-palette-contrast-50);
      border: 2px solid var(--light-blue-palette-50);
      &:hover {
        box-shadow: var(--shadows-palette-4);
      }
    }
    &-grey {
      background-color: var(--neutral-palette-12);
      color: var(--neutral-palette-contrast-12);
    }
    &-grey-primary {
      transition: 0.2s linear 0s;
      background-color: var(--light-blue-palette-50);
      color: var(--light-blue-palette-contrast-50);

      &[disabled] {
        background-color: var(--neutral-palette-12);
        color: var(--neutral-palette-contrast-12);
        opacity: 1;
      }
    }
  }
  height: auto;
}
.vcim {
  &-button.mdc-button {
    height: auto;
    border-radius: 12px;
    &.br-2 {
      border-radius: 2px;
    }
    &.sm-button {
      padding: 0.5rem 1.25rem;
      border-radius: 4px;
      font-weight: 700;
      font-size: 1rem;
    }
    &.mdc-button--outlined {
      &.mat-primary {
        color: var(--light-blue-palette-50);
        border: 2px solid;
        &:hover {
          color: var(--light-blue-palette-200);
        }
      }
    }
    &[disabled] {
      opacity: 0.5;
    }
    &.md-button {
      padding: 1.375rem 2.5rem;
    }
  }
  &-icon-button {
    &.mat-mdc-icon-button.mat-mdc-button-base {
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .mat-mdc-button-touch-target {
        width: 100%;
        height: 100%;
      }
    }
    &.stroked {
      border: 1px solid var(--neutral-palette-5);
    }
    &.md-button {
      width: 2rem;
      height: 2rem;
      padding: 0.4rem;
    }
    &.sm-button {
      width: 1.7rem;
      height: 1.7rem;
      padding: 0.4rem;
    }
  }
}

@include media-md {
  .vcim {
    &-button.mdc-button {
      &.md-button {
        font-size: 1rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
}
