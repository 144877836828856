@import '../variables';

.mat-mdc-text-field-wrapper {
  .mat-mdc-form-field-infix {
    min-height: auto;
    width: auto;
  }
}
.vcim {
  &-search {
    width: 100%;
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
      background-color: var(--neutral-palette-2);
      color: var(--neutral-palette-contrast-2);
      border-radius: 4px;
      font-size: 1rem;
      .mdc-line-ripple {
        display: none;
      }
      .mdc-text-field__input {
        color: inherit;
        width: calc(100% - 2.5rem);
      }

      .mat-mdc-form-field-infix {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
    &.md-field {
      .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
        .mat-mdc-form-field-infix {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
  &-select,
  &-reg-input {
    border-radius: 12px;
    .mdc-text-field--outlined .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      .mdc-notched-outline__trailing {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
    &.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    &.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    &.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: var(--neutral-palette-11);
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 0.6875rem;
      padding-bottom: 0.6875rem;
    }
    .mat-mdc-form-field-error,
    mat-hint {
      text-align: right;
      font-size: 0.875rem;
      color: var(--error-palette-500);
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    mat-hint {
      position: relative;
      animation: uppend 0.2s forwards;
    }
    .mat-mdc-form-field-hint-wrapper {
      padding: 0.25rem 0;
    }
    .mat-mdc-form-field-hint-spacer {
      display: none;
    }
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
      font-size: 1rem;
      color: var(--texts-palette-1);
    }
  }
  &-survey-field {
    &.mat-mdc-form-field {
      background-color: var(--neutral-palette-1);
      color: var(--neutral-palette-contrast-1);
    }
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      transition: 0.1s linear 0s;
    }
  }
}
@keyframes uppend {
  0% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--neutral-palette-1);
  color: var(--neutral-palette-contrast-1);
  .mdc-line-ripple {
    display: none;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: var(--neutral-palette-3);
  }
}

.mat-mdc-form-field-subscript-dynamic-size {
  .mat-mdc-form-field-error-wrapper {
    padding: 0.25rem 0;
  }
}

.mat-mdc-form-field-textarea-control.mdc-text-field__input {
  resize: none;
}

@include media-lg {
  .mat-mdc-form-field,
  .mat-mdc-floating-label {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.mat-mdc-select-value {
  color: inherit;
  padding-right: 5px;

}
.mat-mdc-select {
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;

  .mat-mdc-select-value-text {
    white-space: initial;
  }
}
.mat-mdc-option {
  min-height: auto;
}
.mat-mdc-form-field {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 12px;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 0;
  }
}
.date-field {
  width: 185px;
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
      margin: auto;
    }
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 6px 10px;
    color: var(--neutral-palette-13);
  }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    font-size: 14px;
    color: var(--neutral-palette-13);
  }
  .mdc-text-field {
    border-radius: 12px;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 12px;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 0;
  }
}
.mat-mdc-option.mdc-list-item,
.mdc-list-item__primary-text {
  color: inherit;
}
.mdc-text-field {
  border-radius: 8px !important;
}
