@import '../variables';

.vcim-tab-group {
  .mat-mdc-tab-header {
    margin-bottom: 2rem;
  }
  .mat-mdc-tab-list {
    flex-grow: 0;
    border-radius: 5px;
    background-color: var(--neutral-palette-2);
    color: var(--neutral-palette-contrast-2);
    padding: 2px;
  }
  .mdc-tab-indicator {
    display: none;
  }
  .mat-mdc-tab.mdc-tab {
    height: auto;
    padding: 0.625rem 1.25rem;
    border-radius: 5px;
    color: var(--neutral-palette-13);
    font-size: 1rem;
    font-weight: 600;

    &.mdc-tab--active {
      background-color: var(--light-blue-palette-50);
      color: var(--light-blue-palette-contrast-50);
    }
    .mdc-tab__text-label {
      color: inherit;
      transition: 0s;
      white-space: normal;
    }
    &.mat-mdc-tab-disabled {
      opacity: 1;
      background-color: var(--neutral-palette-9);

      &.mdc-tab--active {
        background-color: var(--neutral-palette-8);
        color: var(--neutral-palette-1);
      }
    }
  }
  &.centered {
    .mat-mdc-tab-label-container {
      justify-content: center;
    }
  }
}

@include media-md {
  .vcim-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-list {
        width: 100%;
        .mat-mdc-tab-labels {
          display: flex;
          flex-direction: column;
          > * {
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.vcim-stepper-btns.mat-button-toggle-group {
  border: none;
  .mat-button-toggle {
    border: none;
    color: var(--neutral-palette-13);
    margin: 0 2rem;
    padding: 0.5rem;
    background-color: transparent;
    transition: 0.2s linear 0s;
    &:hover:not([disabled]) {
      color: var(--neutral-palette-11);
      .mat-button-toggle-focus-overlay {
        background-color: transparent;
        opacity: 1;
      }
    }
    &.completed {
      color: var(--texts-palette-1);
    }
    &.mat-button-toggle-checked {
      color: var(--light-blue-palette-50);
    }
  }
  .mat-button-toggle-label-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
