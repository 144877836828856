@import "../../../styles/New/variables";


.vcim-checkbox.mat-mdc-checkbox {
  &.color-cyan {
    --mdc-checkbox-selected-checkmark-color: var(--light-blue-palette-contrast-50);
    --mdc-checkbox-selected-focus-icon-color: var(--light-blue-palette-50);
    --mdc-checkbox-selected-hover-icon-color: var(--light-blue-palette-50);
    --mdc-checkbox-selected-icon-color: var(--light-blue-palette-50);
    --mdc-checkbox-selected-pressed-icon-color: var(--light-blue-palette-50);
    --mdc-checkbox-unselected-focus-icon-color: var(--neutral-palette-10);
    --mdc-checkbox-unselected-hover-icon-color: var(--neutral-palette-8);
    --mdc-checkbox-disabled-selected-icon-color: var(--neutral-palette-10);
    --mdc-checkbox-disabled-unselected-icon-color: var(--neutral-palette-10);
    --mdc-checkbox-unselected-icon-color: var(--neutral-palette-10);
    --mdc-checkbox-unselected-pressed-icon-color: var(--neutral-palette-10);
  }

  .mdc-checkbox__ripple {
    transition: 0.1s linear 0s;
  }
  .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
    opacity: 0;
  }
  .mdc-checkbox__native-control {
    &.mdc-checkbox--anim-checked-unchecked,
    &.mdc-checkbox--anim-unchecked-checked {
      & ~ .mdc-checkbox__ripple {
        opacity: 0.16 !important;
      }
    }
  }
}
.mat-mdc-checkbox .mdc-form-field {
  color: inherit !important;
}
.mat-mdc-radio-button .mdc-form-field {
  color: inherit !important;
}
.vcim-hidden-radio {
  .mat-mdc-radio-button {
    background-color: var(--neutral-palette-4);
    color: var(--neutral-palette-contrast-4);
    transition: 0.2s linear 0s;
    border-radius: 8px;
    .mdc-form-field {
      color: inherit;
      width: 100%;
      label {
        width: 100%;
        height: 100%;
        padding: 0.75rem 1rem;
        cursor: pointer;
      }
    }
    .mdc-radio {
      display: none;
    }
    &.mat-mdc-radio-checked {
      background-color: var(--light-blue-palette-50);
      color: var(--light-blue-palette-contrast-50);
    }
  }
}
.vcim-cyan-radio {
  padding-right: 2rem;
  .mat-mdc-radio-button.color-cyan {
    // todo
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-focus-icon-color: #212121;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: var(--cyan-palette-50);
    --mdc-radio-selected-hover-icon-color: var(--cyan-palette-50);
    --mdc-radio-selected-icon-color: var(--cyan-palette-50);
    --mdc-radio-selected-pressed-icon-color: var(--cyan-palette-50);
    --mat-mdc-radio-ripple-color: #000;
    --mat-mdc-radio-checked-ripple-color: var(--cyan-palette-50);
  }
}

.vcim-chips {
  .mdc-evolution-chip-set__chips {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5rem;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--neutral-palette-4);
    color: var(--texts-palette-1);
    min-width: auto;
    margin: 0;

    .mdc-evolution-chip__action {
      margin: auto;
    }
    &.mat-mdc-chip-selected {
      background-color: var(--light-blue-palette-50);
      color: var(--light-blue-palette-contrast-50);
    }
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: inherit;
  }
  .mat-mdc-standard-chip {
    height: 2.75rem;
  }
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
    padding-right: 1rem;
  }
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@include media-lg {
  .vcim-chips {
    .mdc-evolution-chip-set__chips {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
@include media-md {
  .vcim-chips {
    .mdc-evolution-chip-set__chips {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@include media-sm {
  .vcim-chips {
    .mdc-evolution-chip-set__chips {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include media-xs {
  .vcim-chips {
    .mdc-evolution-chip-set__chips {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.mat-mdc-radio-group {
  .mdc-form-field {
    &>label {
      
    width: 100%;
    }
    width: 100%;
  }
}
.vcim-green-radio  .mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--cyan-palette-50);
  --mdc-radio-selected-hover-icon-color: var(--cyan-palette-50);
  --mdc-radio-selected-icon-color: var(--cyan-palette-50);
  --mdc-radio-selected-pressed-icon-color: var(--cyan-palette-50);
  --mat-mdc-radio-checked-ripple-color: var(--cyan-palette-50);
}