.vcim-calendar {
  background-color: var(--neutral-palette-4);
  color: var(--texts-palette-1);
  border-radius: 12px;
  .mat-calendar-controls {
    margin-top: 0;
    border-bottom: 1px solid var(--neutral-palette-8);
    padding-bottom: 8px;
  }
  .mat-calendar-body-label {
    opacity: 0;
  }
  .mat-calendar-table-header {
    display: none;
  }
  .mat-calendar-body-cell {
    width: 30px;
    height: 30px;
    font-size: 14px;
    color: inherit;
    position: relative;
  }
  .mat-calendar-body-cell-container {
    width: auto !important;
    padding: 4px 0 !important;
    text-align: center;
  }
  .mat-calendar-controls {
    .mat-calendar-spacer {
      display: none;
    }
    .mat-mdc-button {
      margin: auto;
    }
    .mat-calendar-previous-button {
      order: -1;
    }
  }
  .mat-calendar-body-cell-content {
    border-radius: 5px;
  }
}
