@use "sass:math";
@import "../variables";

@each $item in $spacing-sizes {
  .p-#{$item} {
    padding-left: #{$item * 0.25}rem !important;
    padding-right: #{$item * 0.25}rem !important;
    padding-top: #{$item * 0.25}rem !important;
    padding-bottom: #{$item * 0.25}rem !important;
  }
  .px-#{$item} {
    padding-left: #{$item * 0.25}rem !important;
    padding-right: #{$item * 0.25}rem !important;
  }
  .py-#{$item} {
    padding-top: #{$item * 0.25}rem !important;
    padding-bottom: #{$item * 0.25}rem !important;
  }
  .pt-#{$item} {
    padding-top: #{$item * 0.25}rem !important;
  }
  .pb-#{$item} {
    padding-bottom: #{$item * 0.25}rem !important;
  }
  .pl-#{$item} {
    padding-left: #{$item * 0.25}rem !important;
  }
  .pr-#{$item} {
    padding-right: #{$item * 0.25}rem !important;
  }
  .m-#{$item} {
    margin-left: #{$item * 0.25}rem !important;
    margin-right: #{$item * 0.25}rem !important;
    margin-top: #{$item * 0.25}rem !important;
    margin-bottom: #{$item * 0.25}rem !important;
  }
  .mx-#{$item} {
    margin-left: #{$item * 0.25}rem !important;
    margin-right: #{$item * 0.25}rem !important;
  }
  .my-#{$item} {
    margin-top: #{$item * 0.25}rem !important;
    margin-bottom: #{$item * 0.25}rem !important;
  }
  .mt-#{$item} {
    margin-top: #{$item * 0.25}rem !important;
  }
  .mb-#{$item} {
    margin-bottom: #{$item * 0.25}rem !important;
  }
  .ml-#{$item} {
    margin-left: #{$item * 0.25}rem !important;
  }
  .mr-#{$item} {
    margin-right: #{$item * 0.25}rem !important;
  }

  .m--#{$item} {
    margin-left: #{$item * 0.25}rem !important;
    margin-right: #{$item * 0.25}rem !important;
    margin-top: #{$item * 0.25}rem !important;
    margin-bottom: #{$item * 0.25}rem !important;
  }
  .mx--#{$item} {
    margin-left: -#{$item * 0.25}rem !important;
    margin-right: -#{$item * 0.25}rem !important;
  }
  .my--#{$item} {
    margin-top: -#{$item * 0.25}rem !important;
    margin-bottom: -#{$item * 0.25}rem !important;
  }
  .mt--#{$item} {
    margin-top: -#{$item * 0.25}rem !important;
  }
  .mb--#{$item} {
    margin-bottom: -#{$item * 0.25}rem !important;
  }
  .ml--#{$item} {
    margin-left: -#{$item * 0.25}rem !important;
  }
  .mr--#{$item} {
    margin-right: -#{$item * 0.25}rem !important;
  }
  .gap-#{$item} {
    gap: #{$item * 0.25}rem !important;
  }
}

// margins to use

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

@each $name, $val in $breakpoints {
  @media screen and (min-width: #{math.div($val,16)}rem) {
    @each $item in $spacing-sizes {
      .p-#{$name}-#{$item} {
        padding-left: #{$item * 0.25}rem !important;
        padding-right: #{$item * 0.25}rem !important;
        padding-top: #{$item * 0.25}rem !important;
        padding-bottom: #{$item * 0.25}rem !important;
      }
      .px-#{$name}-#{$item} {
        padding-left: #{$item * 0.25}rem !important;
        padding-right: #{$item * 0.25}rem !important;
      }
      .py-#{$name}-#{$item} {
        padding-top: #{$item * 0.25}rem !important;
        padding-bottom: #{$item * 0.25}rem !important;
      }
      .pt-#{$name}-#{$item} {
        padding-top: #{$item * 0.25}rem !important;
      }
      .pb-#{$name}-#{$item} {
        padding-bottom: #{$item * 0.25}rem !important;
      }
      .pl-#{$name}-#{$item} {
        padding-left: #{$item * 0.25}rem !important;
      }
      .pr-#{$name}-#{$item} {
        padding-right: #{$item * 0.25}rem !important;
      }
      .m-#{$name}-#{$item} {
        margin-left: #{$item * 0.25}rem !important;
        margin-right: #{$item * 0.25}rem !important;
        margin-top: #{$item * 0.25}rem !important;
        margin-bottom: #{$item * 0.25}rem !important;
      }
      .mx-#{$name}-#{$item} {
        margin-left: #{$item * 0.25}rem !important;
        margin-right: #{$item * 0.25}rem !important;
      }
      .my-#{$name}-#{$item} {
        margin-top: #{$item * 0.25}rem !important;
        margin-bottom: #{$item * 0.25}rem !important;
      }
      .mt-#{$name}-#{$item} {
        margin-top: #{$item * 0.25}rem !important;
      }
      .mb-#{$name}-#{$item} {
        margin-bottom: #{$item * 0.25}rem !important;
      }
      .ml-#{$name}-#{$item} {
        margin-left: #{$item * 0.25}rem !important;
      }
      .mr-#{$name}-#{$item} {
        margin-right: #{$item * 0.25}rem !important;
      }

      .m-#{$name}--#{$item} {
        margin-left: #{$item * 0.25}rem !important;
        margin-right: #{$item * 0.25}rem !important;
        margin-top: #{$item * 0.25}rem !important;
        margin-bottom: #{$item * 0.25}rem !important;
      }
      .mx-#{$name}--#{$item} {
        margin-left: -#{$item * 0.25}rem !important;
        margin-right: -#{$item * 0.25}rem !important;
      }
      .my-#{$name}--#{$item} {
        margin-top: -#{$item * 0.25}rem !important;
        margin-bottom: -#{$item * 0.25}rem !important;
      }
      .mt-#{$name}--#{$item} {
        margin-top: -#{$item * 0.25}rem !important;
      }
      .mb-#{$name}--#{$item} {
        margin-bottom: -#{$item * 0.25}rem !important;
      }
      .ml-#{$name}--#{$item} {
        margin-left: -#{$item * 0.25}rem !important;
      }
      .mr-#{$name}--#{$item} {
        margin-right: -#{$item * 0.25}rem !important;
      }
      .gap-#{$name}-#{$item} {
        gap: #{$item * 0.25}rem !important;
      }
    }

    .m-#{$name}-auto {
      margin: auto;
    }

    .mx-#{$name}-auto {
      margin-left: auto;
      margin-right: auto;
    }

    .my-#{$name}-auto {
      margin-top: auto;
      margin-bottom: auto;
    }

    .mt-#{$name}-auto {
      margin-top: auto;
    }

    .mb-#{$name}-auto {
      margin-bottom: auto;
    }

    .ml-#{$name}-auto {
      margin-left: auto;
    }

    .mr-#{$name}-auto {
      margin-right: auto;
    }
  }
}
