@use 'sass:math';
@import "../variables";

.vcim-grid {
  display: grid!important;
}

@each $item in $grid-cols {
  .vcim-grid-size-#{$item} {
    grid-template-columns: repeat($item, 1fr);
  }
}

@each $name, $val in $breakpoints {
  @media screen and (min-width: #{math.div($val,16)}rem) {
    @each $item in $grid-cols {
      .vcim-grid-size-#{$item}-#{$name} {
        grid-template-columns: repeat($item, 1fr);
      }
    }
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
@each $item in $grid-cols {
    .col-#{$item} {
      width: #{$item * $grid-col-size};
    }
  }

  @each $name, $val in $breakpoints {
    @media screen and (min-width: #{math.div($val,16)}rem) {
      @each $item in $grid-cols {
        .col-#{$name}-#{$item} {
          width: #{$item * $grid-col-size};
        }
      }
    }
  }
