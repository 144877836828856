@use "sass:math";
@import "../variables";


@each $item in $font-sizes {
  .f-#{$item} {
    font-size: #{math.div($item,16)}rem!important;
    line-height: #{math.div($item,14)}rem!important;
  }
}
@each $item in $font-weights {
  .fw-#{$item} {
    font-weight: #{$item}!important;
  }
}
.fw-inherit {
  font-weight: inherit!important;
}

@each $name, $val in $breakpoints {
  @media screen and (min-width: #{math.div($val,16)}rem) {
    @each $item in $font-sizes {
      .f-#{$name}-#{$item} {
        font-size: #{math.div($item,16)}rem!important;
        line-height: #{math.div($item,14)}rem!important;
      }
    }
  }
}