.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--shadows-palette-2);
}

.mat-drawer {
  background-color: var(--neutral-palette-1);
  color: var(--neutral-palette-contrast-1);
}
.mat-drawer-container {
  background-color: var(--neutral-palette-1);
  color: var(--neutral-palette-contrast-1);
  .mat-sidenav-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
.mat-drawer-container-has-open {
  z-index: 100000!important;
}
.mat-expansion-panel.vcim-expansion-reset {
  box-shadow: none;
  background-color: transparent;
  color: inherit;
  .mat-expansion-panel-header {
    height: auto;
    padding: 0;
    &.mat-expanded {
      height: auto;
    }
  }
  .mat-expansion-panel-header-title {
    color: inherit;
    margin: 0;
  }
  .mat-expansion-panel-body {
    padding: 0;
  }
  &.mat-expansion-panel-spacing {
    margin: 0;
  }
}
.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--light-blue-palette-500) !important;
}
.mat-step-header .mat-step-icon {
  width: 1.875rem;
  height: 1.875rem;
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--neutral-palette-2);
  --mdc-plain-tooltip-supporting-text-color: var(--neutral-palette-11);
}
mat-datepicker-toggle.disabled-ripple {
  .mdc-icon-button__ripple {
    display: none;
  }
}
.mdc-menu-surface {
  background-color: var(--neutral-palette-1);
  color: var(--neutral-palette-contrast-1);
}
.material-icons {

  font-family: 'Material Icons'!important;
}
