@font-face {
  font-family: fontIcons;
  //  src: url('vcim-icons.eot');
  src: //url('vcim-icons.eot?#iefix') format('embedded-opentype'),
         // url('vcim-icons.ttf') format('truetype'),
          //url('vcim-icons.svg#ProductIconsFontvNew') format('svg');
    url("vcim-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.vcim-icon {
  &::before {
    font-family: fontIcons;
    font-style: normal;
    font-weight: initial;
    font-size: inherit;
    color: inherit;
    text-transform: initial;
    line-height: initial;
  }
  &.ic {
    &-user:before {
      content: "a";
    }
    &-search:before {
      content: "b";
    }
    &-arrow-down:before {
      content: "c";
    }
    &-facebook:before {
      content: "d";
    }
    &-twitter:before {
      content: "e";
    }
    &-instagram:before {
      content: "f";
    }
    &-linkedin:before {
      content: "g";
    }
    &-youtube:before {
      content: "h";
    }
    &-burger:before {
      content: "q";
    }

    // &-covid:before {
    //   content: "p";
    // }
    // &-dentistry:before {
    //   content: "k";
    // }
    // &-subspecialties:before {
    //   content: "j";
    // }
    // &-vaccinations:before {
    //   content: "l";
    // }
    // &-laboratory:before {
    //   content: "m";
    // }
    // &-medical-care:before {
    //   content: "i";
    // }
    // &-pediatrics:before {
    //   content: "o";
    // }
    // &-instrumental:before {
    //   content: "n";
    // }
    &-mail:before {
      content: "t";
    }
    &-location:before {
      content: "u";
    }
    &-phone:before {
      content: "s";
    }
    &-time:before {
      content: "r";
    }
    &-arrow-right:before {
      content: "v";
    }
    &-toastr-success:before {
      content: "w";
    }
    &-toastr-error:before {
      content: "x";
    }
    &-toastr-warning:before {
      content: "y";
    }
    &-toastr-info:before {
      content: "z";
    }
    &-no-image:before {
      content: "A";
    }
    &-share:before {
      content: "B";
    }
    &-filled-arrow:before {
      content: "C";
    }
    &-globe:before {
      content: "D";
    }
    &-close:before {
      content: "E";
    }
    &-add:before {
      content: "F";
    }
    &-edit:before {
      content: "G";
    }
    &-switch-account:before {
      content: "J";
    }
    &-file:before {
      content: "H";
    }
    &-add-file:before {
      content: "I";
    }
    &-burger-add:before {
      content: "K";
    }
    &-filter:before {
      content: "L";
    }
    &-thin-date:before {
      content: "M";
    }
    &-thin-location:before {
      content: "N";
    }
    &-thin-time:before {
      content: "O";
    }
    &-download:before {
      content: "P";
    }
    &-edit-2:before {
      content: "Q";
    }
    &-policy:before {
      content: "R";
    }
    &-service:before {
      content: "S";
    }
    &-doctor:before {
      content: "T";
    }
    &-delete:before {
      content: "U";
    }
    &-payment:before {
      content: "V";
    }
    &-idram:before {
      content: "W";
    }
    &-circle-close:before {
      content: "X";
    }
  }
}
