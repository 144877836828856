.vcim {
  &-stepper {
    &-register {
      background-color: transparent;
      .mat-horizontal-stepper-header-container {
        justify-content: center;
      }
      .mat-step-header {
        pointer-events: none !important;
        padding: 0;
        flex-shrink: 0;
        &:hover {
          background: transparent;
        }
        .mat-step-label.mat-step-label-active {
          color: inherit;
        }
        .mat-step-label-selected {
          font-weight: bold;
        }
        .mat-step-icon {
          border: 1px solid;
          color: #cacad2;
          background-color: transparent;
          &-selected,
          &-state-done,
          &-state-edit {
            background-color: var(--light-blue-palette-50);
            color: var(--light-blue-palette-contrast-50);
          }
        }
      }
      .mat-stepper-horizontal-line {
        margin: 0 1rem;
        max-width: 6.25rem;
        width: 100%;
        flex: initial;
      }
      .mat-horizontal-content-container {
        display: none;
      }
    }
  }
}
