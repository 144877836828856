.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      max-height: 90vh !important;
      background-color: var(--neutral-palette-1);
      color: var(--neutral-palette-contrast-1);
      border-radius: 20px;
    }
  }
}
