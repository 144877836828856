.vcim-code-input {
  width: 19.75rem;
  position: relative;
  display: flex;
  height: 4rem;
  input {
    border: none;
    background-color: transparent;
    letter-spacing: 4rem;
    width: 25rem;
    height: 4rem;
    padding: 0.9rem 1.3rem;
    position: absolute;
    left: 0;
    clip: rect(0px, 19.75rem, 4rem, 0px);
    font: inherit;
    cursor: inherit;
    &:focus {
      border: none;
      outline: none;
    }
  }
  &-box {
    position: absolute;
    width: calc(25% - 1rem);
    height: 4rem;
    border: 1px solid;
    border-radius: 12px;
    pointer-events: none;

    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      left: calc(25% + 0.33rem);
    }
    &:nth-child(3) {
      right: calc(25% + 0.33rem);
    }
    &:nth-child(4) {
      right: 0;
    }
  }
}