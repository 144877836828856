$color-negative-rating: var(--warning-palette-500) !default;
$color-ok-rating: var(--warning-palette-500) !default;
$color-positive-rating: var(--warning-palette-500) !default;
$color-default-rating: var(--warning-palette-500) !default;

@import "css-star-rating/scss/star-rating";

.star.disabled,
.rating.disabled .star-container .star,
.rating.disabled .rating.label-value .star-container .star,
.rating.disabled .star-container .rating.star .star.label-value,
.rating.disabled .star-container .rating.star .star-container .star,
.rating.disabled .star.label-value {
  opacity: 1;
}
.star.medium,
.rating.medium .star.label-value,
.rating.medium .rating.star .star.label-value,
.rating.medium .rating.label-value .star,
.rating.medium .star {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.5rem;
}
.rating:not(.disabled) {
  .star-container {
    cursor: pointer;
  }
}
