.toast-container {
  position: fixed;
  top: 1rem;
  z-index: 10000;
  width: 100%;
  max-width: 25rem;
  right: 1rem;

  .ngx-toastr {
    padding: 1rem 1rem 1rem 3.375rem;
    border-radius: 8px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      font-size: 1.5rem;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1.5rem;
      font-family: fontIcons;
      font-style: normal;
    }

    &.toast-success {
      border: 1px solid var(--success-palette-500);
      background-color: var(--success-palette-100);
      &::after {
        content: "w";
        color: var(--success-palette-500);
      }
    }
    &.toast-warning {
      border: 1px solid var(--warning-palette-500);
      background-color: var(--warning-palette-100);
      &::after {
        content: "y";
        color: var(--warning-palette-500);
      }
    }
    &.toast-error {
      border: 1px solid var(--error-palette-500);
      background-color: var(--error-palette-100);

      &::after {
        content: "x";
        color: var(--error-palette-500);
      }
    }
    &.toast-info {
      border: 1px solid var(--info-palette-500);
      background-color: var(--info-palette-100);

      &::after {
        content: "z";
        color: var(--info-palette-500);
      }
    }
  }
}
