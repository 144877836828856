@import '../variables';

.vcim-table-container.mat-mdc-table {
  thead {
    border-radius: 5px;
    background-color: var(--neutral-palette-2);
    color: var(--neutral-palette-13);
    tr {
      height: auto;
    }
    .mat-mdc-header-cell {
      color: inherit;
      border: none;
    }
  }
  .mdc-data-table__row {
    height: auto;
  }
  .mdc-data-table__cell {
    color: inherit;
  }
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 0.5rem;
  }
  &.disabled {
    thead {
      background-color: var(--neutral-palette-14);
      color: var(--neutral-palette-13);
    }
  }
}

@include media-md {
  .vcim-table-container.mat-mdc-table {
    thead {
      display: none;
    }
    .mdc-data-table__row {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      margin-bottom: 1rem;
      border: 1px solid var(--neutral-palette-8);
    }
    .mdc-data-table__cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: right;
      width: 100%;

      &::before {
        content: attr(data-label);
        font-weight: 700;
        text-align: left;
        padding-right: 0.5rem;

        font-size: 0.85em;
      }
    }
    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 1rem;
      max-width: auto;
    }
  }
}
@include media-sm {
  .vcim-table-container.mat-mdc-table {
    .mdc-data-table__cell {
      &::before {
        font-size: 0.7em;
      }
    }
    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 0.5rem;
      max-width: auto;
    }
  }
}

.vcim-paginator-container {
  .mat-mdc-paginator-container {
    padding: 0;
  }
  .mat-mdc-paginator-range-actions {
    width: 100%;
    .mat-mdc-paginator-range-label {
      margin: 0 auto 0 0;
      color: var(--neutral-palette-13);
    }
  }
}
